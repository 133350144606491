.user-auth-modal {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.327);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
}

.user-auth-modal .user-auth-container {
    position: relative;
    min-height: 600px;
    min-width: 1000px;
    overflow: hidden;
    border-radius: 1.5rem;
    padding: 10px;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
    background-color: #fff;
    box-shadow: 0 4px 20px #0000001a;
    display: grid;
    grid-template-columns: 4fr 3fr;
}

.user-auth-modal .user-auth-container .auth-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.253);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-auth-modal .user-auth-container svg {
    position: absolute;
    width: 20px;
    right: 30px;
    top: 30px;
    cursor: pointer;
}


.user-auth-modal .user-auth-container .web-cam-container {
    min-height: 580px;
    width: 100%;
    border-radius: 1.5rem;
    overflow: hidden;
    background-color: var(--lightest-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.web-cam-container .web-cam-wrapper {
    position: relative;
    display: flex;
    height: 200px;
    width: 200px;
    background-color: rebeccapurple;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid black;
}
.user-auth-container .auth-pin-input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 5px;
}

.auth-form-container .form-info {
    margin-bottom: 40px;
}

.auth-form-container .form-info h1 {
    text-align: center;

}

.auth-form-container .form-switch-wrapper {
    width: 100%;

}

.auth-form-container .form-switch-wrapper button {
    width: 100%;
    border: 1px solid var(--navy);
    border-radius: 1.5rem;
    padding: 15px 0;
    background-color: #fff;
    color: var(--navy);
    transition: 0.1s ease-in-out;
}

.auth-form-container .form-switch-wrapper button:hover {
    background-color: var(--navy);
    color: #fff;
    cursor: pointer;
}


.user-auth-container .auth-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.user-auth-container .auth-form-container .form-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.auth-form-container .form-wrapper .form-section {
    width: 350px;
    height: auto;
    padding: 10px 0 30px;
    overflow: hidden;
    border-radius: 10px;
    transition: .8s ease-in-out;
}

@media screen and (max-width: 820px) {
    .user-auth-modal {
        display: block;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .user-auth-modal .user-auth-container {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 90%;
        height: auto;
        margin: 20px;
    }
    .auth-form-container .form-wrapper .form-section {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .user-auth-modal .user-auth-container {
        margin: 0;
        border-radius: 0;
    }
}
.camera-wrapper {
    height: 100%;
    width: 100%;
}
.camera-wrapper .auth-user-img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}
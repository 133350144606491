.pin-input {
    text-align: center;
    font-size: 16px;
    padding: 15px;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    background-color: var(--white);
    width: 15px;
    margin-bottom: 10px;
}
.user-auth {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color: var(--white);
}

.user-auth button {
    width: 200px;
    margin: 0 auto;
    padding: 15px 0;
    border: none;
    background-color: var(--navy);
    color: var(--white);
    border-radius: 5px;
}

.user-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    flex-direction: column;
}
.user-info p {
    margin-bottom: 10px;
    font-size: 20px;
    margin-top: 5px;
}
.user-info table {
    margin-bottom: 20px;
}
.user-info table,
th,
td {
    border: 1px solid black;
    padding: 10px;
}

@media screen and (max-width: 820px) {
    .user-auth {
        min-height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .user-info {
        height: 100vh;
        margin-top: 0;
    }
}
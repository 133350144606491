.regitration-form {
    width: 100%;
    height: 100%;
}

.regitration-form form {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.regitration-form form .input-container {
    position: relative;
    margin-bottom: 1.25rem;
}

.regitration-form form .input-container input {
    border: none;
    border-bottom: 1px solid var(--lightest-slate);
    height: 3rem;
    width: 100%;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
}

.regitration-form form .input-container input:valid,
.regitration-form form .input-container input:focus {
    border-bottom: 2px solid var(--navy);
    outline: none;
}

.regitration-form form .input-container input:valid+label,
.regitration-form form .input-container input:focus+label {
    color: var(--navy);
    font-size: 0.8rem;
    top: -20px;
    pointer-events: none;
}

.regitration-form form .input-container input::placeholder {
    opacity: 0;
    transition: 0.2s ease-in-out;
    font-size: 14px;
}

.regitration-form form .input-container input:focus::placeholder {
    opacity: 1;
}

.regitration-form form .input-container label {
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: var(--dark-slate);
    display: flex;
    align-items: center;
    cursor: text;
    font-size: 1rem;
    line-height: 1.5rem;
    transition: 0.2s ease-in-out;
}

.regitration-form form button {
    height: 50px;
    width: 100%;
    border-radius: 1.5rem;
    background-color: var(--navy);
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--white);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
}
.dot-elastic {
    position: relative;
    margin: 0 auto;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-elastic::before {
    left: -15px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
    left: 15px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
    0% {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(1, 1.5);
    }

    50% {
        transform: scale(1, 0.67);
    }

    75% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElastic {
    0% {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1, 1.5);
    }

    75% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElasticAfter {
    0% {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1, 0.67);
    }

    75% {
        transform: scale(1, 1.5);
    }

    100% {
        transform: scale(1, 1);
    }
}